import { useEffect, useState } from 'react';
import { ResizeObserver } from 'resize-observer';
import _ from 'lodash';
import { usePersistedValue } from '../react-hooks';

export type DimensionsCallback<R = void> = (width: number, height: number) => R;

export const useElementDimensions =
  (elementId: string, dimensionsCallback: DimensionsCallback, debounceDuration: number) => {
    useEffect(() => {
      const element = document.getElementById(elementId);
      if (!element) {
        console.warn(`[useElementDimensions] could not find element with id '${elementId}'`); // eslint-disable-line no-console
        return () => {};
      }

      const callback = (entries) => entries
        .forEach(({ contentRect }) => dimensionsCallback(contentRect.width, contentRect.height));
      const observer = new ResizeObserver(
        debounceDuration ? _.debounce(callback, debounceDuration) : callback,
      );
      observer.observe(element);
      return () => observer.disconnect();
    }, [elementId, dimensionsCallback, debounceDuration]);
  };

export const useViewport = <S = [width: number, height: number]>(
  getValue?: DimensionsCallback<S>, debounceDuration?: number,
): S => {
  let value: S;
  let setValue: () => void;
  const [customValue, setCustomValue] =
  useState(getValue(window.innerWidth, window.innerHeight));

  if (!getValue) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const persistedWidth = usePersistedValue(width);
    const persistedHeight = usePersistedValue(height);
    value = [width, height] as any;
    setValue = () => {
      if (window.innerWidth !== persistedWidth.get()) {
        persistedWidth.set(window.innerWidth);
        setWidth(window.innerWidth);
      }
      if (window.innerHeight !== persistedHeight.get()) {
        persistedHeight.set(window.innerHeight);
        setHeight(window.innerHeight);
      }
    };
  } else {
    const persistedCustomValue = usePersistedValue(customValue);
    value = customValue;
    setValue = () => {
      const currentValue = getValue(window.innerWidth, window.innerHeight);
      if (currentValue !== persistedCustomValue.get()) {
        setCustomValue(currentValue);
        persistedCustomValue.set(currentValue);
      }
    };
  }

  useEffect(() => {
    const callback = debounceDuration ? _.debounce(setValue, debounceDuration) : setValue;
    window.addEventListener('resize', callback);
    return () => {
      setCustomValue(null);
      window.removeEventListener('resize', callback);
    };
  }, []);

  return value;
};
