// define colors here
export const colors = {
  white: '#fff',
  lightGray: '#e6e6e6',
  greenishGrey: '#F8F7E1',
  midGray: '#949494',
  steel: '#878787',
  gray: '#444',
  secondaryGray: '#505050',
  textBlack: '#333333',
  black: '#000',
  green: '#19BE51',
  transparentGreen: 'rgba(199, 241, 213, 1)',
  lightGreen: '#E8F6F0',
  seaGreen: '#48937F',
  lightSeaGreen: '#D4EEDE',
  seaGreen10: 'rgb(72 147 127 / 10%)',
  darkGreen: '#1E705A',
  buttonGreen: '#BBDAD2',
  balanceGreen: '#0F9089',
  mint: '#D1F2DC',
  lightMint: '#F5F9EC',
  orange10: 'rgb(221 112 25 / 15%)',
  orange: '#DD7019',
  limeGreen20: 'rgba(158, 199, 64, 0.2)',
  oliveGreen: '#7EAA00',
  mediumYellow: '#FFEDAE',
  outlineYellow: '#A1A14B',
  burntOrange: '#E64500',
  burntOrange10: 'rgb(230 69 0 / 10%)',
  red10: 'rgb(210 38 0 / 10%)',
  red: '#D22600',
  errorRed: '#FBE9E6',
  sand: '#FBF8F3',
  sand50: 'rgb(251 248 243 / 50%)',
  sandDark: '#E2DCD1',
  sand40: 'rgb(251 248 243 / 40%)',
  brightBrown: '#E8D0C2',
  lightBrown: '#C0B3A5',
  lightTan: '#EFE3CC',
  lightBrown50: 'rgb(192 179 165 / 50%)',
  gold: '#FFC700',
  darkGold: '#D6CAB9',
  mutedGold: '#C6BDAF',
  highlight: '#FFECCA',
  grayish: '#A7A7A7',
  lightestGray: '#F4F4F4',
  draftGray: '#C4C4C4',
  outlineGray: '#9E9E9E',
  grey: '#4F4F4F',
  greyer: '#DEDCD8',
  textGrey: '#171717',
  linkBlue: '#308de8',
  audienceLogGray: '#E0E0E0',
  progressGray: '#D8D1C5',
  progressBlue: '#19C5DD',
  inactiveGray: '#BDBDBD',
  yellow: '#EAE000',
  highlightYellow: '#F0EF9E',
  mildYellow: '#FEE992',
  highlightOrange: '#FFE9C3',
  hoverTan: '#EBE2D2',
  hoverGreen: '#DDF5E5',
  borderGray: '#8692A6',
  anotherGray: '#F7F7F7',
  lighterBorderGray: '#DADADA',
  dropdownGray: '#696F79',
  statusDotNew: '#521B97',
  statusDotOpen: '#19BE51',
  statusDotPending: '#E8D696',
  statusDotClosed: '#E5E5E5',
  statusDotCompleted: '#48bd5a',
  statusDotInQueue: '#B38FEC',
  statusDotScheduled: '#1967DD',
  purple: '#563B8E',
  ctaPurple: '#6C27FF',
  softPurple: '#5059C9',
  lightPurple: '#EAD9FF',
  headerPurple: '#542FA4',
  borderPurple: '#C5AAFF',
  aiLavender: '#E7D1FF',
  deepPurple: '#5000FB',
  deepPurple10: 'rgba(80, 0, 251, .1)',
  deepPurple20: 'rgba(80, 0, 251, .2)',
  aiPurple: '#F9EDFD',
  lavender: 'rgba(216, 201, 246, .5)',
  unassignRed: '#e6450040',
  triggerGreen: '#9EC740',
  beige: '#F2EDE4',
  selectorGray: '#D3D3D3',
  sandOutline: 'rgba(224, 198, 183, 0.2)',
  headerYellow: '#ffe1af',
  sandyBrown: '#FDFBF9',
  borderBrown: '#DEDBD6',
};