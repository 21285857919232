import styled from 'styled-components';
import _ from 'lodash';

export interface TextStyleProps {
  cursor?: string;
  children?: any;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number | string;
  color?: string;
  textTransform?: string;
  lineHeight?: number | string;
  margin?: string;
  padding?: string;
  letterSpacing?: number | string;
  whiteSpace?: string;
  textOverflow?: string;
  overflow?: string;
  background?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  borderRadius?: number | string;
  hoverColor?: string;
  lineClamp?: number;
  boxOrient?: string;
  display?: string;
  alignItems?: string;
  textAlign?: string;
  wordWrap?: string;
  borderBottom?: string;
  alignSelf?: string;
  overflowWrap?: string;
};

// TODO: We need to find a way to not use !important on our stylesheets
// -- its generally a bad practice to use it all the time

export const TextStyleP = styled.p<TextStyleProps>`
  strong {
    font-weight: 500;
  }
  line-height: 1.5;
  margin: 0px;

  font-family: ${(props) => props.$fontFamily};
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight}!important;
  color: ${(props) => props.$color};
  text-transform: ${(props) => props.$textTransform};
  display: ${(props) => props.$display};
  align-items: ${(props) => props.$alignItems};
  background: ${(props) => props.$background};
  margin: ${(props) => props.$margin};
  padding: ${(props) => props.$padding};
  line-height: ${(props) => props.$lineHeight};
  letter-spacing: ${(props) => props.$letterSpacing};
  white-space: ${(props) => props.$whiteSpace};
  text-overflow: ${(props) => props.$textOverflow};
  overflow: ${(props) => props.$overflow};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius};
  border-bottom: ${(props) => props.$borderBottom};
  -webkit-line-clamp: ${(props) => props.$lineClamp};
  -webkit-box-orient: ${(props) => props.$boxOrient};
  text-align: ${(props) => props.$textAlign};
  word-wrap: ${(props) => props.$wordWrap};
  cursor: ${(props) => props.$cursor};
  align-self: ${(props) => props.$alignSelf};
  overflow-wrap: ${(props) => props.$overflowWrap};

  &:hover {
    color: ${(props) => props.$hoverColor};
  }
`;

export const TextStyleDiv = styled.div<TextStyleProps>`
  strong {
    font-weight: 500;
  }
  line-height: 1.5;
  margin: 0px;

  font-family: ${(props) => props.$fontFamily};
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight}!important;
  color: ${(props) => props.$color};
  text-transform: ${(props) => props.$textTransform};
  display: ${(props) => props.$display};
  align-items: ${(props) => props.$alignItems};
  background: ${(props) => props.$background};
  margin: ${(props) => props.$margin};
  padding: ${(props) => props.$padding};
  line-height: ${(props) => props.$lineHeight};
  letter-spacing: ${(props) => props.$letterSpacing};
  white-space: ${(props) => props.$whiteSpace};
  text-overflow: ${(props) => props.$textOverflow};
  overflow: ${(props) => props.$overflow};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius};
  border-bottom: ${(props) => props.$borderBottom};
  -webkit-line-clamp: ${(props) => props.$lineClamp};
  -webkit-box-orient: ${(props) => props.$boxOrient};
  text-align: ${(props) => props.$textAlign};
  word-wrap: ${(props) => props.$wordWrap};
  cursor: ${(props) => props.$cursor};
  align-self: ${(props) => props.$alignSelf};
  overflow-wrap: ${(props) => props.$overflowWrap};

  &:hover {
    color: ${(props) => props.$hoverColor};
  }
`;

export interface TextProps extends TextStyleProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
}


export const TextDiv = ({ children, id, className, onClick, onKeyPress, ...styleProps }: TextProps) => (
  <TextStyleDiv
    id={id}
    className={className}
    onClick={onClick}
    onKeyPress={onKeyPress}
    {..._.mapKeys(styleProps, (_, k) => '$' + k)}
  >
    {children}
  </TextStyleDiv>
);


export const Text = ({ children, id, className, onClick, onKeyPress, ...styleProps }: TextProps) => (
  <TextStyleP
    id={id}
    className={className}
    onClick={onClick}
    onKeyPress={onKeyPress}
    {..._.mapKeys(styleProps, (_, k) => '$' + k)}
  >
    {children}
  </TextStyleP>
);
