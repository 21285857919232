import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';

import { UserInfoContext } from '@/components/subcomponents/user/user-info';
import {
  accountManagerSeesAllTicketsSelector,
  accountPrivateConversationsEnabledSelector,
  accountSoloConversationsEnabledSelector,
} from '@/redux/app/selectors/settings';

export const useSoloModeCheck = (subdomain: string) => {
  const { canManage, admin } = React.useContext(UserInfoContext);
  const soloConversationsEnabled = useSelector(accountSoloConversationsEnabledSelector);
  const privateConversationsEnabled = useSelector(accountPrivateConversationsEnabledSelector);
  const accountManagerSeesAllTickets = useSelector(accountManagerSeesAllTicketsSelector);

  if (soloConversationsEnabled) return !canManage(subdomain) || !accountManagerSeesAllTickets;
  if (privateConversationsEnabled) return !canManage(subdomain) && !admin;
  return false;
};

export const useHover = (ref: RefObject<HTMLElement>, callback: any) => {
  React.useEffect(() => {
    if (!ref.current) return;
    const handleMouseOver = () => callback(true);
    const handleMouseOut = () => callback(false);

    ref.current.addEventListener('mouseover', handleMouseOver);
    ref.current.addEventListener('mouseout', handleMouseOut);

    return () => {
      ref.current?.removeEventListener('mouseover', handleMouseOver);
      ref.current?.removeEventListener('mouseout', handleMouseOut);
    };
  }, [ref, callback]);
}

// eslint-disable-next-line max-len
export const useClickOutside = (ref: RefObject<HTMLElement>, callback: any, isCapture = false, isMousedown = false) => {
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref.current?.contains(event.target) || event.key === 'Escape') callback(false);
    };

    if (isMousedown) {
      setTimeout(() => window.document.addEventListener('keydown', handleClickOutside, { capture: isCapture }), 0);
      setTimeout(() => window.document.addEventListener('mousedown', handleClickOutside, { capture: isCapture }), 0);
      return () => {
        window.document.removeEventListener('keydown', handleClickOutside);
        window.document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    setTimeout(() => window.document.addEventListener('click', handleClickOutside, { capture: isCapture }), 0);
    setTimeout(() => window.document.addEventListener('keyup', handleClickOutside, { capture: isCapture }), 0);
    return () => {
      window.document.removeEventListener('click', handleClickOutside);
      window.document.removeEventListener('keyup', handleClickOutside);
    };
  }, [ref, callback]);
};

export const useClickOutsideNoKeyup = (ref: any, callback: any, isCapture = false) => {
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if ((ref.current && !ref.current.contains(event.target)) || event.key === 'Escape') callback(false);
    };
    window.document.addEventListener('click', handleClickOutside, { capture: isCapture });

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
};
export const useSecondRenderEffect = (callback: any, deps: any) => {
  const ref = React.useRef(false);
  React.useEffect(() => {
    if (ref.current) callback();
    ref.current = true; // eslint-disable-line no-param-reassign
  }, deps);
};

export const useNoMobileHeader = (mobile: boolean) => {
  const page = document.querySelector('.ui-page');
  React.useEffect(() => {
    if (mobile) {
      page?.classList.add('header-hidden');
    } else {
      page?.classList.remove('header-hidden');
    }

    return () => page?.classList.remove('header-hidden');
  }, [mobile]);
};
