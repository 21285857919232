import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;
export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`;
export const FlexAlignCenter = styled(Flex)`
  align-items: center;
`;
export const FlexAlignStart = styled(Flex)`
  align-items: flex-start;
`;
export const FlexAlignEnd = styled(Flex)`
  align-items: flex-end;
`;
export const FlexCenter = styled(Flex)`
  justify-content: center;
  align-items: center;
`;
export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;
export const FlexColumnJustifyCenter = styled(FlexColumn)`
  justify-content: center;
`;
export const FlexColumnJustifyStart = styled(FlexColumn)`
  justify-content: start;
`;
export const FlexColumnJustifyEnd = styled(FlexColumn)`
  justify-content: flex-end;
`;
export const FlexColumnCenter = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`;
export const FlexColumnAlignEnd = styled(FlexColumn)`
  align-items: flex-end;
`;
export const FlexColumnAlignStart = styled(FlexColumn)`
  align-items: flex-start;
`;
export const FlexColumnAlignCenter = styled(FlexColumn)`
  align-items: center;
`;
export const FlexJustifyStart = styled(Flex)`
  justify-content: flex-start;
`;
export const FlexJustifyEnd = styled(Flex)`
  justify-content: flex-end;
`;
export const FlexJustifyBetween = styled(Flex)`
  justify-content: space-between;
`;
export const FlexJustifyCenter = styled(Flex)`
  justify-content: center;
`;
export const FlexJustifyBetweenAlignCenter = styled(FlexJustifyBetween)`
  align-items: center;
`;
export const Absolute = styled.div<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}>`
  position: absolute;
  ${({ $left }) => ($left ? `left: ${$left};` : '')}
  ${({ $right }) => ($right ? `right: ${$right};` : '')}
  ${({ $top }) => ($top ? `top: ${$top};` : '')}
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom};` : '')}
`;
